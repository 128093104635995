import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModText from "../../../components/Chronology/Modules/ModText";
import InlineLink from "../../../components/Chronology/Modules/InlineLink";
import ModDatafactCountry from "../../../components/Chronology/Modules/ModDatafactCountry";
import ModBuscandoDatos from "../../../components/Chronology/Modules/ModBuscandoDatos";
import ModDailyHighlight from "../../../components/Chronology/Modules/ModDailyHighlight";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";
import ModDotList from "../../../components/Chronology/Modules/ModDotList";

export const frontmatter = {
  title: "Día 77",
  week: "Semana 11",
  day: "29",
  month: "May",
  monthNumber: "05",
  date: "2020-05-29",
  path: "/cronologia/semana-11#dia-29-may/",
};

const Day77 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
        <ModDatafactCountry
          fecha={frontmatter.date}
          country="US"
          data="casosFallecidos"
        />
      </ContentLeft>

      <ContentRight>
        <ModDailyHighlight>
          Tercer día del periodo de luto oficial
        </ModDailyHighlight>
        <ModText>
          La{" "}
          <InlineLink link="https://momo.isciii.es/public/momo/dashboard/momo_dashboard.html">
            última actualización del MOMO
          </InlineLink>
          , informe de información sobre mortalidad diaria por todas las causas,
          aflora 16.000 fallecidos extraordinarios durante el periodo de
          duración de la epidemia en España. Si se atribuyesen al covid-19, la
          cifra de fallecidos por SARS-CoV-2 se elevaría a 43.000 personas.
        </ModText>
        <ModText>
          A partir del próximo lunes 1 de junio el 70% de la población estará en
          Fase 2, el otro 30% en Fase 1, y Formentera y las tres islas canarias
          de El Hierro, la Graciosa y la Gomera pasarán a Fase 3.
        </ModText>
        <ModText>
          <strong>TERRITORIOS QUE PASAN A LA FASE 2:</strong>
        </ModText>
        <ModDotList>
          <strong>Castilla-La Mancha:</strong> Toledo, Ciudad Real y Albacete.
        </ModDotList>
        <ModDotList>
          <strong>Comunidad Valenciana:</strong> Castellón, Valencia y Alicante.
        </ModDotList>
        <ModDotList>
          <strong>Cataluña:</strong> Girona, Cataluña Central, Alt Penedès y El
          Garraf
        </ModDotList>
        <ModDotList>
          <strong>Murcia:</strong> Totana.
        </ModDotList>
        <ModDotList>
          <strong>Andalucia:</strong> Granada y Málaga.
        </ModDotList>
        <ModDotList>
          <strong>Castilla y León:</strong> Area de Salud de El Bierzo
        </ModDotList>

        <ModText>
          <strong>TERRITORIOS QUE PASAN A LA FASE 3:</strong>
        </ModText>
        <ModDotList>
          <strong>Islas Baleares:</strong> Formentera.
        </ModDotList>
        <ModDotList>
          <strong>Canarias:</strong> La Gomera, El Hierro y La Graciosa.
        </ModDotList>

        <ModBuscandoDatos num="random" />
      </ContentRight>
    </LayoutDay>
  );
};
export default Day77;
